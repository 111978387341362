import styled from 'styled-components';

import { devices } from './breakpoints';

export const TitleH1 = styled.h1`
  color: var(--fontColorDarkBlue);
  font-family: var(--font-roboto);
  font-size: var(--size32);
  font-weight: var(--bold700);
  line-height: normal;
  text-align: left;
  @media only screen and ${devices.desktop} {
    text-align: center;
    font-size: var(--size48);
    line-height: 58px;
  }
`;

export const TitleH2 = styled.h2`
  color: var(--fontColorDarkBlue);
  font-family: var(--font-roboto);
  font-size: var(--size24);
  font-weight: var(--semibold600);
  line-height: normal;
  text-align: left;
  @media only screen and ${devices.desktop} {
    text-align: center;
    font-size: var(--size40);
    line-height: 46px;
  }
`;

export const TitleH3 = styled.h3`
  color: var(--fontColorDarkBlue);
  font-family: var(--font-roboto);
  font-size: var(--size20);
  font-weight: var(--semibold600);
  line-height: normal;
  text-align: left;
  line-height: normal;
  @media only screen and ${devices.desktop} {
    text-align: left;
    font-size: var(--size32);
    line-height: 40px;
  }
`;

export const RegularText = styled.p`
  color: var(--fontColorDarkBlue);
  font-family: var(--font-roboto);
  font-size: var(--size14);
  font-style: normal;
  font-weight: var(--regular400);
  line-height: 20px;
  white-space: break-spaces;
  text-align: left;
`;
