import React, { FC } from 'react';
import styled from 'styled-components';

import { devices } from '@/styles/breakpoints';
interface IProps {
  title: string;
}

export const Label: FC<IProps> = ({ title, style }) => {
  return <FreeBtn style={{ style }}>{title}</FreeBtn>;
};

const FreeBtn = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  /* margin-left: 10px; */
  width: 56px;
  height: 32px;
  padding: 6px, 12px, 6px, 12px;
  border-radius: 100px;
  font-family: var(--font-roboto);
  background-color: var(--mainGreen);
  color: var(--whiteColor);
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  @media only screen and ${devices.desktop} {
    width: 60px;
    height: 33px;
    font-size: 18px;
    line-height: 21px;
  }
`;
