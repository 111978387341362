'use client';

import React, { FC } from 'react';
import styled from 'styled-components';

import background from '../../public/banner_big.webp';
import Form from '../Form/Form';

import { Container } from '@/components/Container';
import Section from '@/components/Section/Section';
import { backgroundImageMixin } from '@/styles/BackgroundimageMixin';
import { devices } from '@/styles/breakpoints';

interface IProps {
  title: string;
  description: string;
}

export const BookDemo: FC<IProps> = (props) => {
  return (
    <Wrapper>
      <WrapperInner>
        <Section
          style={{ paddingBottom: '0px' }}
          whiteTextColor={true}
          {...props}
        >
          <Form />
        </Section>
      </WrapperInner>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${backgroundImageMixin(background.src)};
  /* margin-top: 24px; */
  @media only screen and ${devices.desktop} {
    /* margin-top: 40px; */
  }
`;

const WrapperInner = styled(Container)`
  padding-top: 20px;
  padding-bottom: 40px;
  & .controls {
    justify-content: center;
    & .control-cancel {
      display: none;
    }
  }

  @media only screen and ${devices.desktop} {
    padding-bottom: 80px;
  }
`;
