'use client';
import React, { FC } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { devices } from '../../styles/breakpoints';

import { TitleH1, TitleH2 } from '@/styles/typography';
import { RegularText } from '@/styles/typography';

interface IProps {
  title?: string;
  description?: string;
  whiteTextColor?: boolean;
  children: React.ReactNode;
  style?: CSSProperties;
}

const Section: FC<IProps> = ({
  title,
  description,
  whiteTextColor,
  children,
  style,
  titleAs = 'h2',
}) => {
  return (
    <SectionWrapper style={style}>
      {title ? (
        titleAs === 'h2' ? (
          <Title $iswhite={whiteTextColor}>{title}</Title>
        ) : (
          <TitleMain $iswhite={whiteTextColor}>{title}</TitleMain>
        )
      ) : null}

      {description && (
        <Description $iswhite={whiteTextColor}>{description}</Description>
      )}
      {children}
    </SectionWrapper>
  );
};

const Title = styled(TitleH2)`
  margin-bottom: 24px;
  color: ${(props) => (props.$iswhite ? 'white' : '')};
  @media only screen and ${devices.desktop} {
    max-width: 744px;
    margin: 0 auto 16px;
  }
`;

const TitleMain = styled(TitleH1)`
  color: var(--fontColorDarkBlue);
  font-family: var(--font-roboto);
  font-size: var(--size24);
  font-weight: var(--semibold600);
  line-height: normal;
  text-align: left;
  margin-bottom: 24px;

  @media only screen and ${devices.desktop} {
    text-align: center;
    font-size: var(--size40);
    line-height: 46px;
    max-width: 744px;
    margin: 0 auto 16px;
  }
`;

const Description = styled(RegularText)`
  text-align: left;
  margin-bottom: 24px;
  color: ${(props) => (props.$iswhite ? 'white' : '')};
  @media only screen and ${devices.desktop} {
    text-align: center;
    max-width: 744px;
    margin: 0 auto 40px;
  }
`;

const SectionWrapper = styled.div`
  width: 100%;
  padding: 12px 0;
  @media only screen and ${devices.desktop} {
    padding: 40px 0;
  }
  &.mobile_hidden {
    display: none;
    @media only screen and ${devices.desktop} {
      display: block;
    }
  }
`;

export default Section;
