'use client';

import React, { FC } from 'react';
import styled from 'styled-components';

import background from '../../public/banner_small.webp';

import Button from '@/components/Button/Button';
import { Label } from '@/components/Label/Label';
import { StarIcon } from '@/public/icons';
import { backgroundImageMixin } from '@/styles/BackgroundimageMixin';
import { devices } from '@/styles/breakpoints';
import { TitleH3 } from '@/styles/typography';

interface IProps {
  title: string;
  subTitle: string;
  thirtTitle: string;
  secondaryTitle: string;
  listItems: [];
}

export const Banner: FC<IProps> = ({
  title,
  subTitle,
  secondaryTitle,
  listItems,
}): JSX.Element => {
  return (
    <Wrapper>
      <LabelWrapper>
        <Label title="Free" />
      </LabelWrapper>
      <ContentWrapper>
        <div>
          <div>
            {' '}
            <SecondaryTitle>
              <span>{subTitle}</span>
            </SecondaryTitle>
            <Title>
              {title}{' '}
              {!secondaryTitle && (
                <LabelWrapperDesctop>
                  <Label title="Free" />
                </LabelWrapperDesctop>
              )}
            </Title>
            {secondaryTitle && (
              <Title>
                {secondaryTitle}
                <LabelWrapperDesctop>
                  <Label title="Free" />
                </LabelWrapperDesctop>
              </Title>
            )}
          </div>

          {!!listItems?.length && (
            <ListContentWrapper>
              <ListItems>
                {listItems.map((item, idx, arr) => (
                  <React.Fragment key={idx}>
                    <Item>
                      <span className="item-icon">
                        <StarIcon />
                      </span>
                      <span className="item-title">{item.title}</span>
                    </Item>

                    {idx !== arr.length - 1 && (
                      <li>
                        <Divider />
                      </li>
                    )}
                  </React.Fragment>
                ))}
              </ListItems>
            </ListContentWrapper>
          )}
        </div>
        <div>
          <Button
            style={{
              width: '200px',
            }}
            onClick={() =>
              window.open('https://app.orcatec.com/auth/signup', 'e_blank')
            }
            fullWidth={true}
          >
            Get started for free
          </Button>
        </div>
      </ContentWrapper>

      <ButtonWrapper>
        <Button
          onClick={() =>
            window.open('https://app.orcatec.com/auth/signup', 'e_blank')
          }
          fullWidth={true}
        >
          Get started for free
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.article`
  ${backgroundImageMixin(background.src)};
  padding: 24px 16px;
  position: relative;
  @media only screen and ${devices.desktop} {
    border-radius: 10px;
    padding: 40px;
    max-width: 1128px;
    margin: 0 auto;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  & > div:last-child {
    display: none;
  }

  @media only screen and ${devices.desktop} {
    display: flex;

    align-items: center;
    justify-content: space-between;
    gap: 40px;
    width: 100%;
    & > div:last-child {
      display: block;
    }
    & button {
    }
  }
`;

const LabelWrapper = styled.div`
  margin-bottom: 24px;
  @media only screen and ${devices.desktop} {
    display: none;
  }
`;

const LabelWrapperDesctop = styled.div`
  display: none;
  @media only screen and ${devices.desktop} {
    display: inline-block;
    margin-left: 24px;
    /* position: absolute;
    right: -80px;
    top: 0; */
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 24px;
  @media only screen and ${devices.desktop} {
    display: none;
  }
`;

const ListContentWrapper = styled.div`
  padding-top: 24px;
  @media only screen and ${devices.desktop} {
    padding: 24px 0 0 10px;
  }
`;

const Title = styled(TitleH3)`
  position: relative;
  text-align: left;
  color: var(--whiteColor);

  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  /* margin-bottom: 24px; */
  font-size: 24px;
  /* max-width: 600px; */
  @media only screen and ${devices.desktop} {
    margin-bottom: 0px;
  }
`;

const SecondaryTitle = styled.p`
  font-family: var(--font--roboto);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 8px;
  @media only screen and ${devices.desktop} {
    margin-bottom: 0px;
  }
`;

const ListItems = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 16px;

  @media only screen and ${devices.desktop} {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-left: -8px;
  }
`;
const Item = styled.li`
  display: grid;
  grid-template-columns: 30px auto;

  & .item-title {
    font-family: var(--forn-roboto);
    color: var(--whiteColor);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

const Divider = styled.div`
  display: none;
  @media only screen and ${devices.desktop} {
    display: block;
    width: 1px;
    height: 20px;
    background-color: var(--whiteColor);
    opacity: 0.2;
    margin: 0 24px;
  }
`;
