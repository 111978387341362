'use client';

import React, { FC, useCallback, useRef } from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import { ArrowIcon, CommaIcon } from '@/public/icons';
import { devices } from '@/styles/breakpoints';
import { TitleH2 } from '@/styles/typography';
import { RegularText } from '@/styles/typography';

interface IProps {
  title: string;
  description?: string;
  items: {
    text: string;
    name: string;
    position: string;
    image?: object;
  }[];
}

export const TestimonalItem = ({
  item,
}: {
  item: {
    text: string;
    name: string;
    position: string;
    image?: object;
  };
}): JSX.Element => {
  return (
    <ItemWrapper>
      <ItemComma>
        <CommaIcon />
      </ItemComma>
      <ItemTitle>{item.text}</ItemTitle>
      <CardFooterWrapper>
        {item?.image && (
          <ImageWrapper>
            <Image src={item.image} alt="pic" />
          </ImageWrapper>
        )}

        <CardFooter>
          <ItemAuthor>{item.name}</ItemAuthor>
          <ItemPosition>{item.position}</ItemPosition>
        </CardFooter>
      </CardFooterWrapper>
    </ItemWrapper>
  );
};

export const Testimonals: FC<IProps> = ({ title, items, description }) => {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <Wrapper>
      <ActionsWrapper>
        <div>
          <TitleH2 style={{ marginBottom: '16px', textAlign: 'center' }}>
            {title}
          </TitleH2>
          {description && (
            <RegularText style={{ maxWidth: '600px', textAlign: 'center' }}>
              {description}
            </RegularText>
          )}
        </div>
      </ActionsWrapper>

      <Swiper
        ref={sliderRef}
        slidesPerView={1}
        spaceBetween={24}
        navigation={true}
        effect="fade"
        loop={true}
        // autoplay={true}
        modules={[Autoplay, Navigation]}
        className="testimonals-swiper"
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
      >
        {items.map((item, idx) => (
          <SwiperSlide key={idx}>
            <TestimonalItem item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
      <ButtonWrapper>
        <Icon onClick={handlePrev} $isprev={true}>
          <ArrowIcon color="#262626" />
        </Icon>

        <Icon onClick={handleNext}>
          <ArrowIcon color="#262626" />
        </Icon>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & .testimonals-swiper {
    padding: 40px 0 80px 0;
  }
  & .swiper-slide {
    height: auto;
  }

  @media only screen and ${devices.desktop} {
    & .testimonals-swiper {
      padding: 40px 0;
    }
  }
`;

const ItemWrapper = styled.article`
  border-radius: 10px;
  padding: 24px;
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px solid #e6e8ec;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media only screen and ${devices.desktop} {
    line-height: 40px;
    max-width: 552px;
    padding: 32px;
  }
`;

const ItemComma = styled.span`
  color: rgba(253, 164, 40, 1);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`;

const ItemTitle = styled(RegularText)`
  margin-bottom: 16px;
  /* min-height: 116px; */
  @media only screen and ${devices.desktop} {
    font-size: 18px;
    line-height: 28px;
  }
`;

const CardFooterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;

  gap: 12px;
`;

const CardFooter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: start;
  gap: 4px;
  /* margin-top: auto; */
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const ItemAuthor = styled.span`
  font-family: var(--font-roboto);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #192d52;
`;
const ItemPosition = styled.span`
  font-family: var(--font-roboto);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #192d52;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 2;
  @media only screen and ${devices.desktop} {
    position: unset;
    transform: translate(0%, 0%);
  }
`;

const Icon = styled.div<{ $isprev?: boolean }>`
  transition: transform 0.3s;
  transform: ${(p) => (p.$isprev ? 'rotate(90deg)' : 'rotate(-90deg)')};
  width: 40px;
  height: 40px;
  background-color: white;
  border: 1px solid #e6e8ec;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
