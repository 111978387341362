import React, { FC } from 'react';
import styled from 'styled-components';

import Accordion from '@/components/Accordeon/Accordeon';

interface IProps {
  title: string;
  items: {
    title: string;
    text: string;
  }[];
}

export const FAQ: FC<IProps> = ({ items }) => {
  function addArticleJsonLd(items) {
    const mainEntity = items.map((item) => ({
      '@type': 'Question',
      name: item.title,
      acceptedAnswer: {
        '@type': 'Answer',
        text: item.text,
      },
    }));

    return {
      __html: JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        name: 'FAQ',
        mainEntity: mainEntity,
      }),
    };
  }
  return (
    <Wrapper>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={addArticleJsonLd(items)}
        key="faq-jsonld"
      />
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <Accordion key={item.title} title={<p> {item.title}</p>}>
            <div>
              <div>{item.text}</div>
            </div>
          </Accordion>
        </React.Fragment>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.article`
  width: 100%;
  max-width: 744px;
  margin: 0 auto;
  & h2 {
    margin-bottom: 20px;
  }
  & .accordeon-title {
    color: #192d52;

    font-family: var(--font-roboto);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
`;
